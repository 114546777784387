section#cta, section#cta-contact{   
   padding-top: 3em;
   padding-bottom: 3em;   
   position:relative;
   
   .skewbg{
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:-260px;
      overflow:hidden;
      perspective:800px;
      @media only screen and (max-width:960px){
        bottom:-360px
      }
      .bg{
         border-radius:0 100px 0 0;
         position:absolute;
         top:0;
         left:-30vw;
         right:-3vw;
         bottom:0;
         background:@yellow;
         transform-origin:100% 50%;
         transform:rotate(-4deg) skewX(2deg);
         @media only screen and (max-width:767px){
            right:-10vw;
         }

      }
   }
   .row {   
      display: flex;      
      
      @media only screen and (max-width:767px){
          flex-flow: column;
      }
      
      .col-8 {
          flex: 2 1 auto;
          
          @media only screen and (max-width:767px){
            flex: 0 0 ~"calc(100%)";
            margin-bottom: 1em;
          }
      }
      .col-4 {
          flex: 1 1 auto;
          position: relative;
          
          @media only screen and (max-width:767px){
            flex: 0 0 ~"calc(100%)";
            margin-bottom: 1em;
          }
          
      }
      
      .onethird {
          position: relative;
          width: calc(100% / 3 * 1);
          
          @media only screen and (max-width:767px){
            width: 100%;
            margin-bottom: 1em;
            
            img {
                zoom: 2;
                max-width: 100%;
            }
          }
      }
      .twothird {
          position: relative;
          width: calc(100% / 3 * 2);
          
          @media only screen and (max-width:767px){
            width: 100%;
            margin-bottom: 1em;
            
            img {
                zoom: 2;
                max-width: 100%;
            }
          }
          
      }
      .half {
          position: relative;
          width: 50%;
          
          &.contact-image {
              margin-right: 6em;
              overflow: hidden;
              max-height: 220px;
              max-height: 240px;
              width: 32%;
              @media only screen and (max-width:767px){
                  width: 100%;
              }
    
              img {
                  object-fit: cover;
                  width: 100%;
              }
          }
          
          @media only screen and (max-width:1140px){
            width: 50%;
          }
          @media only screen and (max-width:767px){
            width: 100%;
            flex: 0 0 ~"calc(100%)";
            text-align: center;
            margin-bottom: 1em;
            
            img {
                zoom: 2;
                max-width: 100%;
            }
            
            .button {
                position: initial;
                transform: none;
            }
          }
          @media only screen and (max-width:560px){

          }
      }
      
      .button {
        position: absolute;
        top: 50%;                
        right: 0;
        transform: translate(0, -50%);
        margin: 0;
      }
      
      a.button:hover {
          text-decoration: none;
      }
   }
    h2 {            
        line-height:1.8em;
        margin-bottom: 0;
        @media only screen and (max-width:767px){
            padding-left:24px;
            padding-right:24px;
            line-height:1.6em;
            /*font-size:20px;*/
            
            &.title {
                text-align: center;
                
                &:after {
                    left:50%;
                    .translate(-50%, -50%);
                }
            }
        }      
   }
   .button{
      margin:24px auto;
      color:@white;
      background:@dark;
      box-shadow:0px 12px 20px -6px rgba(0,0,0,0.3);
      border-color:@dark;
      padding-top: 6px;
      padding-bottom: 6px;
   }
   
   a {
       color: @darkBlue;
       &:hover {
           text-decoration: underline;
       }
   }
}

body.frontpage {
    section#cta {
        color:@white;
        background: @red;
        
        h2 {
            color:@white;
        }
    }
}

body:not(.frontpage) {
    section#cta {
        color:@white;  
        padding-top: 0;
        padding-bottom: 0;
        .row {
            background: @red;
            padding: 3em; 
            margin-bottom: 4em;
        }
        
        h2 {
            color:@white;
            padding-bottom: 0;
        }
    }
}

section#cta-contact {
    padding-top: 4em;
    padding-bottom: 4em;   
    background: @white;
    font-size: 18px;
    h2 {
        margin-bottom: 1rem;
    }
}
