a{
   color:@red;
   text-decoration:none;
   
   &:hover {
       text-decoration: underline;
   }
}
b, strong{
   font-weight:700;
}


.button{
   display:inline-block;
   border:none;
   line-height:24px;
   padding:3px 15px;
   border-radius:6px;
   text-decoration:none;
   border:solid 2px @yellow;
   color:@white;
   font-family:@bodyFont;
   font-weight:600;
   text-transform:uppercase;
   background:@yellow;
   font-size:13px;
   letter-spacing:0.06em;
   position:relative;
   .transition(background 0.8s 0s @easeOutExpo);
   /*box-shadow:0px 4px 40px -8px @yellow;*/
   @media only screen and (max-width:600px){
      font-size:0.8em;
      letter-spacing:0.04em;
   }
}
.button-b{
   display:inline-block;
   position:relative;
   cursor:pointer;
   color:@dark;
   margin-right:16px;
   font-family:@headFont;
   padding:4px 0;
   &:last-child{
      margin-right:0;
   }
   &:hover{
      color:@white;
      &:before{
         left:-8px;
         right:-8px;
         height:100%;
      }
   }
   &:before{
      content:"";
      position:absolute;
      right:0;
      left:0;
      bottom:0;
      height:2px;
      background:@yellow;
      z-index:-1;
      border-radius:3px;
      .transition(all 0.4s 0s @easeOutExpo);
   }
   
   a {
       color: @black;
       text-decoration: none;       
   }
}

input[type='text'], input[type='tel'], input[type='password'], input[type="radio"], input[type="email"], input[type="submit"], textarea{
   padding:0px 12px;
   height:36px;
   line-height:32px;
   font-size:0.9em;
   font-family:@bodyFont;
   border:none;
   border:solid 2px @white;
   border-radius:6px;
   outline:none;
   -webkit-appearance:none;
   appearance:none;
   box-shadow:0px 0px 30px -18px @dark;
   .transition(border 0.4s 0s ease);
   &[type="radio"]{
      width:16px;
      height:16px;
      line-height:24px;
      position:relative;
      padding:0;
      border-radius:50%;
      border-color:@dark;
      &:checked{
         border-color:@yellow;
         & + span{
            font-weight:bold;
         }
         &:after{
            content:"";
            position:absolute;
            top:50%;
            left:50%;
            .translate(-50%, -50%);
            width:8px;
            height:8px;
            background:@yellow;
            border-radius:50%;
         }
      }
   }
   &[type="submit"]{
      background:@dark;
      color:@white;
      font-size:1em;
      font-weight:bold;
      text-transform:uppercase;
      font-family:@headFont;
      letter-spacing:0.06em;
      cursor:pointer;
      height:42px;
      margin-top:12px;
      border:solid 2px @dark;
      box-shadow:0px 0px 30px -6px @dark;
   }
   &:focus{
      outline:none;
      border:solid 2px @dark;
   }
}
textarea{
   height:220px;
}

.wpcf7-list-item-label{
   vertical-align: super;
}
.section#contact-form .contact-form__container form .wpcf7-form-control-wrap{
   float:left;
   margin-bottom:12px;
}
.video__container222{
   position:absolute;
   top:0px;
   left:50vw;
   right:0px;
   overflow:hidden;
   z-index:2;
   height:100vh;
   .translateZ(0);
   .transition(height 0.8s 0s @easeOutExpo, left 0.8s 0s @easeOutExpo);
   &.open{
      left:-20vw;
      .inner{
         bottom:0;
      }
   }
   .inner{
      background:@dark;
      position:absolute;
      top:-3px;
      left:15vw;
      right:~"calc(-12vw - 6vh)";
      bottom:10vh;
      //border-radius:0 0 0 72px;
      -webkit-transform:skewX(12deg) rotate(-6deg);
      transform:skewX(12deg) rotate(-6deg);
      transform-origin:0% 50%;
      overflow:hidden;
      .bg{
         position:absolute;
         top:0;
         left:-12vw;
         right:0;
         bottom:-5vh;
         -webkit-transform:skewX(-12deg) rotate(6deg);
         transform:skewX(-12deg) rotate(6deg);
         background-position:center center;
         background-size:cover;
         .grayscale(100%);
         &:after{
            content:"";
            position:absolute;
            top:0;
            left:-12vw;
            right:0;
            bottom:-5vh;
            background:@yellow;
            opacity:0.9;

         }
      }
   }
}

.play-button{
   width:160px;
   height:160px;
   position:relative;
   cursor:pointer;
   
   .hexagon-1, .hexagon-2 {
       display: none;
   }
   &.open{
      .hexagon.hexagon-1{
         opacity:0.1;
         .scale(0.6);
      }
      .hexagon.hexagon-2{
         opacity:0.1;
         .scale(0.7);
      }
      .hexagon.hexagon-3{

      }
   }
   &.big{
      width:240px;
      height:240px;
      .hexagon.hexagon-1{
         width:240px;
         height:240px;
         margin-left:-120px;
         margin-top:-120px;
      }
      .hexagon.hexagon-2{
         width:156px;
         height:156px;
         margin-left:-78px;
         margin-top:-78px;
      }
   }
   .parallax-inner{
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
   }
   &:hover{
      .hexagon-1{
         .scale(1.1);
      }
      .hexagon-2{
         .scale(1.1);
      }

   }
   .hexagon{
      width:72px;
      height:72px;
      position:absolute;
      top:50%;
      left:50%;
      margin-left:-36px;
      margin-top:-36px;
      svg{
         position:absolute;
         .scale(1);
         -webkit-filter: drop-shadow( 0px 0px 10px rgba(21, 232,117, .7));
         filter: drop-shadow( 0px 0px 10px rgba(255, 232,117, .7));
      }
      .scale(1);
      &.hexagon-1{
         width:160px;
         height:160px;
         margin-left:-80px;
         margin-top:-80px;
         opacity:0.1;
         .transition(all 1s 0s @easeOutExpo);
      }
      &.hexagon-2{
         width:116px;
         height:116px;
         margin-left:-58px;
         margin-top:-58px;
         opacity:0.1;
         .transition(all 1s 0.1s @easeOutExpo);
      }
      &.hexagon-3{
         svg{
            -webkit-filter: drop-shadow( 0px 0px 10px rgba(2, 20,39, .2));
            filter: drop-shadow( 0px 0px 10px rgba(2, 20,39, .2));
         }
         &:after{
            content:"";
            position:absolute;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 18px solid @dark;
            top:50%;
            left:50%;
            .translate(-50%, -50%);
         }
      }

   }
}

.mouse-hover{
   pointer-events:none;
   width:100%;
   height:100%;
   position:absolute;
   top:50%;
   left:50%;
   transform:translate(-50%, -50%);
   opacity:1;
   .transition(opacity 0.4s 0s @easeOutExpo, transform 0.8s 0s @easeOutExpo);
   .mouse-hover-inner{
      .transition(all 0.05s 0s ease);
   }
}





.tabs{
   margin:24px 0 24px;
   text-align:center;
   user-select:none;
   display:none; //
   .tab{
      padding:4px 18px;
      color:@black;
      display:inline-block;
      cursor:pointer;
      font-size:1.1em;
      .transition(all 0.2s 0s ease);
      &.active{
         background:@yellow;
         color:@white;
         font-weight:600;
         border-radius:3px;
         box-shadow:0px 0px 20px -6px @yellow;
      }
   }
}

.tab-content__container{
   width:100%;
   display:flex;
   flex-flow:row wrap;
   justify-content: flex-start;
   margin-bottom:90px;
   align-content:flex-start;
   .tab-content{
      flex:0 0 ~"calc(33.3333% - 48px)";
      position:relative;
      padding:24px 24px 24px;
      border-radius:6px;
      opacity:1;
      margin:0 18px 40px;
      padding-top:72px;
      display:block;
      .transition(opacity 0.4s 0s ease, display 0s 0s ease);
      &.hide{
         opacity:0;
         display:none;
         .transition(opacity 0.4s 0s ease, display 0s 0.4s ease);
      }
      &:hover{
         &:before{
            background:@white;
            box-shadow:0px 10px 60px -12px rgba(200,200,200,1);
         }
      }
      &:before{
         content:"";
         z-index:-1;
         background:@white;
         position:absolute;
         top:40px;
         left:40px;
         right:0px;
         bottom:0px;
         box-shadow:0px 0px 60px -12px rgba(200,200,200,1);
         .transition(all 0.4s 0s ease);
         border-radius:6px;
      }
      @media only screen and (max-width:900px){
         flex:0 0 ~"calc(50% - 36px)";
         margin-left:12px;
         margin-right:12px;
      }
      @media only screen and (max-width:600px){
         flex:0 0 ~"calc(100% - 24px)";
         margin-left:0;
         margin-right:0;
      }
      .icon{
         position:absolute;
         z-index:3;
         top:0px;
         left:0%;
         margin-left:0px;
         width:80px;
         height:80px;
         .translateZ(0);
         svg.bg{
            .rotate(20deg);
            filter:drop-shadow(0px 0px 10px rgba(0,0,0,0.2));
            path{
               drop-shadow:0px 0px 24px @dark;
               fill:@dark;
            }
         }
         .icon-inner{
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            .scale(0.5);
            svg{
               fill:@white;
            }
            .text{
               .scale(2);
               font-size:4em;
               color:@white;
               font-family:@headFont;
               position:absolute;
               top:50%;
               left:50%;
               .translate(-50%, -50%);
               line-height:0.8em;
               text-align:center;
               span{
                  display:block;
                  font-size:0.55em;
                  line-height:1.2em;
                  text-transform:uppercase;
               }
            }

         }
      }
      h3{
         text-align:left;
         padding-left:64px;
      }
      h5{
         padding-left:64px;
      }
      p{
         padding-top:12px;
         text-align:left;
         padding-bottom:12px;
         padding-left:64px;
      }
      .read-more{
         position:absolute;
         background:@yellow;
         bottom:-18px;
         height:36px;
         padding:0 18px;
         line-height:36px;
         color:@white;
         border-radius:3px;
         right:36px;
         /*box-shadow:0px 0px 36px -12px @yellow;*/
         //font-size:0.9em;
         font-weight: bold;

      }
   }
}

.job{
   cursor:pointer;
   h3{
      text-align:center;
   }
}

.video__container.standalone{
   width:80%;
   margin:60px 10%;
   @media only screen and (max-width:960px){
      width:100%;
      margin:40px 0;
   }
   .video-item{
      max-width:100%;
   }
}

#video__container{
   position:fixed;
   top:0;
   left:0;
   right:0;
   bottom:0;
   z-index:-3;
   opacity:0;
   display:none;
   .transition(all 0s 0.4s ease);
   &.open{
      opacity:1;
      z-index:12 !important;
      display:block;
      .transition(all 0s 0s ease);
      .bg{
         opacity:0.4;
      }
      .video-inner{
         .video-inner2{
            opacity:1;
         }
      }
   }
   .bg{
      top:0;
      left:0;
      right:0;
      bottom:0;
      background:@dark;
      position:absolute;
      opacity:0;
      .transition(all 0.3s 0.1s ease);
   }
   .video-inner{
      width:90%;
      height:0px;
      max-width:960px;
      position:absolute;
      background:@white;
      top:50%;
      left:50%;
      .translate(-50%, -50%);
      .video-inner2{
         height:0;
         padding-top:56.25%;
         position:absolute;
         top:50%;
         left:50%;
         .translate(-50%, -50%);
         background:@white;
         opacity:0;
         width:100%;
         .transition(all 1s 0s ease);
      }
      iframe{
         position:absolute;
         top:0;
         left:0;
         right:0;
         bottom:0;
      }
      .close{
         position:absolute;
         top:-30px;
         right:-30px;
         width:60px;
         height:60px;
         cursor:pointer;
         svg{
            position:absolute;
            width:100%;
            height:100%;
            top:0;
            left:0;
            opacity:1;
            path{
               fill:@yellow;
            }
         }
         span{
            color:@white;
            font-size:32px;
            font-weight:bold;
            .rotate(-45deg);
            line-height:60px;
            text-align:center;
            display:block;
         }
      }
   }
}
div:not(#textOverlay) .wp-block-image{
   width:80%;
   position:relative;
   margin:60px 10%;
   @media only screen and (max-width:960px){
      width:100%;
      margin:40px 0;
   }
   img{
      width:100%;
      height:100%;
      border-radius:12px;
      overflow:hidden;
      box-shadow:0px 12px 60px 0px rgba(0,0,0,0.1);
   }
}


#textOverlay{
   position:fixed;
   top:0;
   left:0;
   right:0;
   bottom:0;
   z-index:-3;
   opacity:0;
   .transition(all 0s 0.4s ease);
   display:none;
   &.open{
      opacity:1;
      z-index:12 !important;
      .transition(all 0s 0s ease);
      display:block;
      .bg{
         opacity:0.4;
      }
      .textcontainer{
         .inner{
            opacity:1;
         }
      }
   }
   &.small{
      .textcontainer{
         width:360px;
         height:360px;
         max-width:90%;
         max-height:90%;
      }

   }
   .bg{
      top:0;
      left:0;
      right:0;
      bottom:0;
      background:@dark;
      position:absolute;
      opacity:0;
      .transition(all 0.3s 0.1s ease);
   }
   .textcontainer{
      width:90vw;
      height:600px;
      max-height:~"calc(100vh - 200px)";
      max-width:960px;
      position:absolute;
      background:@white;
      top:50%;
      left:50%;
      .translate(-50%, -50%);
      border-radius:12px;
      box-shadow:0px 12px 60px 0px rgba(0,0,0,0.1);
      .inner{
         padding:24px 36px;
         overflow:scroll;
         position:absolute;
         top:0;
         left:0;
         right:0;
         bottom:0;
      }
      h2{
         padding-top:16px;
         margin-bottom:0;
         font-size:18px;
      }
      h3{
         margin-bottom:24px;
      }
      a{
         text-decoration: underline;

      }
      .inline-video{
         width:100%;
         height:300px;
         margin:24px auto;
         iframe{
            height:100%;
            width:100%;
         }
      }
      .button__container{
         text-align:center;
      }
      .button{
         margin:24px 0;
         text-decoration:none;
      }
   }
   .close{
      position:absolute;
      top:-30px;
      right:-30px;
      width:60px;
      height:60px;
      cursor:pointer;
      svg{
         position:absolute;
         width:100%;
         height:100%;
         top:0;
         left:0;
         opacity:1;
         path{
            fill:@yellow;
         }
      }
      span{
         color:@white;
         font-size:32px;
         font-weight:bold;
         .rotate(-45deg);
         line-height:60px;
         text-align:center;
         display:block;
      }
   }
}

.video-item{
   .play-button{
      position:absolute;
      top:50%;
      left:50%;
      .translate(-50%, -50%);
   }
}


//voor scholen
.part-1{
   margin-bottom:36px;
   .half-width{
      width:50%;
      float:left;
      padding-right:24px;
   }
   .half-width-list{
      width:50%;
      padding-left:24px;
      float:left;
   }
}

.bouwplein{
	.half{
		width:50%;
		float:left;
		@media only screen and (max-width:700px){
			width:100%;
		}
		&:first-child{
			padding-right:48px;
			@media only screen and (max-width:700px){
				padding-right:0;
			}
		}
		form{
			padding-left:48px;
			@media only screen and (max-width:700px){
				padding-left:0;
			}
			input[type="text"], input[type="email"],textarea{
				width:80%;
				@media only screen and (max-width:700px){
					width:90%;
				}
			}
		}
		p{
			text-align:left !important;
		}
	}
}

#cookies{
	position:fixed;
	left:0;
	right:0;
	bottom:0;
	background:@white;
	box-shadow:0px 2px 12px 0px rgba(0,0,0,0.1);
	padding:12px 0;
	&.hidden{
		bottom:-90px;
	}
	p{
		line-height:1em;
		margin-bottom:0;
		text-align:center;
		a{
			font-weight:400;
		}
		span.accept-cookies{
			font-weight:400;
			background:@yellow;
			padding:2px 8px;
			color:@white;
			border-radius:3px;
			cursor:pointer;
		}
	}
}


.wpcf7-checkbox{
	width:100%;
	display:block;
	.wpcf7-list-item{
		width:100%;
		display:block;
	}
}

/* custom css */
.text-center {
    text-align: center;
}
.hidden {
    display: none !important;
}