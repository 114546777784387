
/////////
//RESET//
/////////
@import 'variables';
@import 'mixins';

/////////
//FONTS//
/////////


///////////
//WEBSITE//
///////////
@import 'website';
@import 'blog-post';
@import 'footer';


//shortcodes
@import 'shortcodes/home-intro';
@import 'shortcodes/clients';
@import 'shortcodes/banner';
@import 'shortcodes/services-small';
@import 'shortcodes/blog';
@import 'shortcodes/social-icons';
@import 'shortcodes/contact';
@import 'shortcodes/contact-form';
@import 'shortcodes/team';
@import 'shortcodes/about';
@import 'shortcodes/media';
@import 'shortcodes/cta';
@import 'shortcodes/agenda';
@import 'shortcodes/education';
@import 'shortcodes/instagram';
@import 'shortcodes/home-wat';
@import 'shortcodes/home-fourblocks';
@import 'shortcodes/home-featured';
@import 'shortcodes/row';

.grecaptcha-badge {
    display: none !important;
}
