section#opleidingen{
    
    .tab-content__container {
        .tab-content.job {
            padding: 0;
            border-radius: 0;
            
            &:before {
                right: 0;
                left: 0;
                bottom: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 0;
            }
            
            h3 {
                margin: 2em auto;
                padding-left: 1.2em;
            }
            
            .job-thumb {
                display: flex;
                margin-bottom: 1em;                
                img {
                    width: 100%;
                    height: auto;
                }
            }
            
        }
        
        .grouping-separator {
            width: 100vw;
            min-height: 5em;
        }
    }
    
   .education-item{
      margin-bottom: 90px;      
   }
   h1{
      font-size:60px;
      @media only screen and (max-width:700px){
         font-size:40px;
         margin-bottom:24px;
      }
   }
   .small-side{
      width:240px;
      float:left;
      padding-right:24px;
      @media only screen and (max-width:700px){
         width:100%;
         padding-right:0;
      }
   }
   .text__container{
      margin-left:240px;
      padding-left:24px;
      @media only screen and (max-width:700px){
         padding-left:0;
         margin-left:0;
      }
      p{
         display:block;

      }
      h4{
         margin-top:2em;
         color:@dark;
      }
   }
   .education-margin{
      height:3em;
      @media only screen and (max-width:700px){
         height:1.1em;
      }
   }
   .jobs-short{
      text-align:right;
      color:@dark;
      font-weight:bold;
      @media only screen and (max-width:700px){
         text-align:left;
      }
   }
}
