section#contact-form{



   .contact-form__container{
      position:relative;
      width:~"calc(100% - 320px)";
      float:left;
      @media only screen and (max-width:767px){
         width:~"calc(100% + 48px)";
      }
      .screen-reader-response{
         display:none;
         &[role='alert']{
            clip:auto;
            height:auto;
            width:100%;
            position:static;
            margin:0px 0 30px;
            display:block;
            font-weight:bold;
            ul{
               display:none;
            }
         }
      }
      form{
         margin:0px 24px 60px 0;
         position:relative;
         //background:@white;
         border-radius:6px;
         color:@black;
         padding:0 30px 30px;
         .wpcf7-response-output{
            display:none;
            &.wpcf7-mail-sent-ok{

            }
         }
         .wpcf7-list-item-label{
            font-size:1em;
         }

         .wpcf7-form-control-wrap{
            width:100%;

            position:relative;
            @media only screen and (max-width:767px){
               width:100%;
            }
            .wpcf7-list-item{
               display:inline-block;
               margin-right:12px;
            }
         }
         .wpcf7-not-valid-tip{
            position:absolute;
            top:-22px;
            left:12px;
            display:block;
            width:100%;
            color:#000;
            font-style:italic;
         }
      }
      input, select{
         &[type="text"], &[type="email"], &[type="tel"]{
            width:100%;
            box-shadow:0 4px 20px 0 rgba(0,0,0,0.1);
            @media only screen and (max-width:767px){
               width:~"calc(100% - 48px)";
            }
         }

         &[type="submit"]{
            width:~"calc(50% - 24px)";
            margin-right:30px;

            @media only screen and (max-width:767px){
               width:~"calc(100% - 24px)";
            }
         }
         &.wpcf7-not-valid{
            border:solid 2px red;
         }
      }
      textarea{
         width:~"calc(200% - 48px)";
         resize:none;
         box-shadow:0 4px 20px 0 rgba(0,0,0,0.1);
         @media only screen and (max-width:767px){
            width:~"calc(100% - 48px)";
         }
      }
   }
   .contact-info{
      float:right;
      width:320px;
      margin:0px 0;
      @media only screen and (max-width:767px){
         float:left;
         width:100%;
         margin-bottom:60px;
      }
      .box{
         padding:24px 24px;
         background:@white;
         margin-bottom:24px;
         position:relative;
         border-radius:6px;
         color:@dark;
         box-shadow:0px 0px 24px -12px @dark;
         p{
            color:@dark;
         }
      }
   }

}



//MEELOOPDAG FORMULIER
#wpcf7-f1373-p1350-o1{
   width:100%;
   max-width:600px;
   margin:0 auto;
   input{
       &[type="text"], &[type="email"], &[type="tel"]{
         width:100%;
      }
   }
   textarea{
      width:100%;
   }
}
