#home-wat{
   padding:0px 0 32px;
   .image__container{
      width:~"calc(50% - 36px)";
      border-radius:6px;
      float:left;
      position:relative;
      @media only screen and (max-width:1024px){
         width:100%;
         max-width:767px;
         margin:0 auto;
         float:none;
      }
      img{
         object-fit:cover;
         position:relative;
         top:0;
         left:0;
         right:0;
         bottom:0;
         width:100%;
         height:100%;
      }
   }
   .text__container{
      width:50%;
      float:right;
      margin-left:0;
      @media only screen and (max-width:1024px){
         width:100%;
         max-width:767px;
         margin:0 auto;
         float:none;
      }
      @media only screen and (max-width:580px){
         columns:1;
      }
      p{
         font-size:15px;
         line-height:1.8em;
      }
      .button-b{
         margin-top:24px;
      }

   }
   .title__container{
      width:100%;
      float:left;
      margin-bottom:48px;
      @media only screen and (max-width:1024px){
         max-width:767px;
         margin:0 auto;
         float:none;
      }
      h2{
         width:70%;
         padding-bottom:12px;
         @media only screen and (max-width:1024px){
            width:100%;
         }

      }
   }

}
