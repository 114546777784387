section#home-intro{
   width:100%;
   position:relative;
   //overflow:hidden;
   .bg__container{
      position:absolute;
      top:0px;
      left:0px;
      right:0px;
      bottom:0px;
      overflow:hidden;
      perspective:800px;
      .bg{
         background:@dark;
         top:0px;
         left:0px;
         right:-30vw;
         bottom:0px;
         position:absolute;
         border-radius:0 0 0 100px;
         transform:rotate(-6deg) skewX(12deg);
         transform-origin:0% ~"calc(50% + 0vw)";
         overflow:hidden;
      }
   }

   .text__container{
      position:relative;
      width:100%;
      max-width:600px;
      margin:0 auto;
      display:block;
      padding:~"calc(90px + 5vw)" 0;
      h1{
         margin-bottom:1em;
         color:@white;
         text-align:center;
      }
      p{
         color:@white;
         font-weight:500;
         text-align:center;
      }
   }
   .button__container{
      margin-top:2em;
      text-align:center;
      .button{
         margin-right:24px;
         border-radius:0px;
         line-height:28px;
         height:28px;
         border-right:none;
         border-top:none;
         border-left:none;
         letter-spacing:0.01em;
         text-transform:none;
         background:none;
         padding:0;
         &:last-child{
            margin-right:0;
         }
      }
   }
   .play-button{
      position:absolute;
      left:65%;
      top:50%;
      .translate(-50% -50%);
      z-index:3;
      .transition(all 1s 0s @easeOutExpo);
      &.open{
         left:50%;
         top:~"calc(100% - 60px - 4vh)";
         width:120px;
         height:120px;
      }

   }
}


.home-hero {
    h1 {
        margin-bottom: 1.2rem;
    }
}
