section#sponsors{
   padding: 60px 0;
   position:relative;
   background: @darkBlue;
   color: @white;
   .skewbg{
      position:absolute;
      top:-75px;
      left:0;
      right:0;
      bottom:-200px;
      overflow:hidden;
      perspective:800px;
      .bg{
         border-radius:100px 0 0 0;
         position:absolute;
         top:0;
         left:0px;
         right:-14vw;
         bottom:0;
         background:@white;
         transform-origin:0% 50%;
         transform:rotate(4deg) skewX(-2deg);
         @media only screen and (max-width:640px){
           left:-24vw;
           right:-54vw;
         }
      }
   }
   .title {
       color: @white !important;
       text-align: center;
       margin-bottom: 1em;
       &::after {
           left: 50%;
           transform: translateX(-50%);
       }
   }
   ul{
      display:flex;
      width:100%;
      li{
         flex-wrap:wrap;
         width:~"calc(100% / 5)";
         height:120px;
         position:relative;
         img{
            position:absolute;
            width:100%;
            height:100%;
            object-fit:contain;
            object-position: center center;
            padding:0 12px;
         }
         
         @media only screen and (max-width:767px){
            width:~"calc(100% / 2)";
         }
      }
   }
}

section#partners {
    padding: 50px 0;
    position:relative;
    background: @white;
    
    .initiatief_samenwerkingspartners {
        p:first-child{
            margin-bottom: 1em;
            text-align: center;
        }        
        a {
            color: @darkBlue;
            text-decoration: underline;
        }
    }
    
    p {
        color: @black;
        margin-bottom: 2em;
    }
    p.intro {
        text-align: center;
        margin-bottom: 1em;
        color: @black;
        a {
            color: @darkBlue;
            text-decoration: underline;
        }
    }
    
    ul {
        display:flex;
        width:100%;
        
        li{
           flex-wrap: wrap;
           width:~"calc(100% / 3)";
           height: 120px;
           position: relative;
           img{              
              width:100%;
              height:100%;
              object-fit: contain;
              object-position: center center;
              padding:0 12px;
           }
           @media only screen and (max-width:767px){
               &{
                   height: inherit
               }               
           }         
        }
    }
}

footer#footer{
   padding: 20px 0;
   background-color: #111;
   color: @white;
   position:relative;
   font-size: 0.9em;
   .skewbg{
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      overflow:hidden;
      perspective:800px;
      .bg{
         border-radius:100px 0 0 0;
         position:absolute;
         top:0;
         left:0px;
         right:-14vw;
         bottom:0;
         background:@dark;
         transform-origin:0% 50%;
         transform:rotate(4deg) skewX(-2deg);
         @media only screen and (max-width:640px){
           left:-24vw;
           right:-54vw;
         }
      }
   }
   .row{
     padding:0 56px;
   }
   .footer-text{
      margin:0 0 56px;
      width:50%;
      float:left;
      padding:0 0px 0 36px;
      @media only screen and (max-width:640px){
        width:100%;
      }
   }

   .logo__container{
      width:200px;
      height:133.333px;
      float:left;
      margin-bottom:24px;
      img{
         width:80%;
         max-width:80%;
         object-fit:contain;
      }
      @media only screen and (max-width:767px){
         float:right;
         display:none;
      }
   }
   .footer-column{
      margin-left:200px;
      @media only screen and (max-width:767px){
         margin-left:0;
         width:100%;
      }
      .column{
         width:~"calc(100% / 3)";
         float:left;
         padding-left:30px;
      }
       a{
          color:@white;
          font-weight:400;
          text-decoration:underline;
       }
   }
   h4{
      color:@white;
   }
   .sub{
      text-align:center;
      font-size:1em;
      margin-top: 0;
      a{
         color:@white;
         font-weight:bold;
         text-decoration:none;
      }
   }
}
