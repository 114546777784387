section.row-section {
    padding-top: 30px;
    padding-bottom: 30px;
    
    &.intro{
        background-color: @white2;
        
        .row {
            text-align: center;
        }
    }    
}