section#instagram-feed{
   position:relative;
   &:before{
      //content:"";
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:-5vh;
      background:@white;
   }
   .text__container{
      text-align: center;
      h1,h2,h3{
        color: @white;
      }
      
      h2.title:after {
          left: 50%;
          transform: translateX(-50%);
      }
      
      .instagram-icon {
        color: @white;
        font-size: 3em;
        margin-bottom: 1em;
        max-width: 6em;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
      
      @media only screen and (max-width:767px){
        width:100%;
        float:none;
        margin-bottom:48px;
      }
   }
   .insta-feed__container{
      width:~"calc(100% - 320px)";
      display:flex;
      flex-flow:row wrap;
      justify-content: flex-start;      
      margin-left: auto;
      margin-right: auto;
      align-content: center;
      @media only screen and (max-width:767px){
         width:100%;
         margin-left:0;

      }
      .insta-feed {
         flex:0 0 ~"calc(33.3333% - 36px)";
         position:relative;
         height:0;
         margin:9px;
         padding-top:~"calc(33.3333% - 36px)";
         overflow:hidden;
         
         @media only screen and (max-width:1140px){
            flex:0 0 ~"calc(33.3333% - 36px)";
         }
         @media only screen and (max-width:767px){
            flex: 0 0 ~"calc(50% - 18px)";            
         }
         @media only screen and (max-width:560px){

         }
         
         
         img{
            object-fit:cover;
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            width:100%;
            height:100%;
         }
      }
   }
   
   .instagram-actions {
       text-align: center;
       margin-top: 2rem;
   }
}

.frontpage {
    .instagram-feed-section {
        background-color: @darkBlue;
        padding-bottom: 4rem;
        padding-top: 4rem;
    }
}
