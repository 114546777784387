section#featured-pages {
    padding-top: 4em;
    padding-bottom: 4em;
    
    font-size: 18px;
    
    .row {
      display: flex;      
      
      @media only screen and (max-width:767px){
          flex-flow: column;
      }
      margin-bottom: ~"calc(2vw + 30px)";
      
      &:last-child {
          margin-bottom: 0;
      }
      
      .onethird {
          position: relative;
          width: calc(100% / 3 * 1);
          
          @media only screen and (max-width:767px){
            width: 100%;
          }
      }
      .twothird {
          position: relative;
          width: calc(100% / 3 * 2);
          
          @media only screen and (max-width:767px){
            width: 100%;
          }
      }
      .half {
          position: relative;
          width: 50%;
          
          @media only screen and (max-width:767px){
            width: 100%;
          }
      }
      
      .button {
         margin-top: 1em;
      }
      
      .image-featured {
          background-size: cover;
          min-height: 300px;
      }
      
      &>.half {
          margin-left: ~"calc(1vw + 40px)";
          &:first-child {
              margin-left: 0;
          }
          
          @media only screen and (max-width:767px){
            margin-left: 0;
            margin-bottom: 1em;
          }
          
      }
      
      
    }
}
