section#media{
   display:block;
   .video-list__container{
      margin:18px 0 48px;
      .video-item{
         width:~"calc(33.333% - 18px)";
         float:left;
         margin-right:18px;
      }
   }
}


.video-item{
   width:100%;
   max-width:800px;
   position:relative;
   margin:0 auto;
   cursor:pointer;
   border-radius:6px;
   overflow:hidden;
   box-shadow:0px 0px 30px -12px @dark;
   &:hover{
      .youtube-embed{
         &:after{
            box-shadow:0px 0px 60px -12px @black;
         }
      }
   }
   .youtube-embed{
      width:100%;
      padding-top:56.25%;
      position:relative;
      .scale(1);
      height:0;
      margin:0 0px;
      &:before{
         content:"";
         z-index:-1;
         background:@dark;
         position:absolute;
         top:0px;
         left:0px;
         right:0px;
         bottom:0px;
         box-shadow:0px 0px 60px -24px @black;
         background:@white;
         .transition(all 0.4s 0s ease);
      }
      .bg{
         position:absolute;
         top:0;
         left:0px;
         right:0px;
         bottom:0;
         opacity:0;
         background-size:cover;

         .transition(all 1s 0s ease);
         &.show{
            opacity:1;
         }
      }
   }
   .text__container{
      padding:18px 18px;
      position:relative;
      margin:0px 0px 0;
      background:@white;
      border-radius:0 0 6px 6px;

   }
}
