section#agenda{
   padding:36px 0;
   position:relative;
   overflow:hidden;
   &.agenda-small{
      padding: 30px 0;
      padding-top: 50px;
   }
   
   &.agenda-full {
       padding-top: ~"calc(2vw + 36px)";
       
       .row {
           
           .tab-content__container {
               .tab-content.event {
                    h3 {
                        margin-bottom: 2em;
                    }
               }
           }
           
           .read-more.button {
               line-height: 30px;
           }
           
           .tab-content.event {
               flex: 0 0 ~"calc(50% - 50px)";
               
               &:nth-child(2n) {
                   margin-right: 0;
                   margin-left: auto;
               }
               
               &:nth-child(2n + 1) {
                   margin-left: 0;
                   margin-right: auto;
               }
               
               @media only screen and (max-width:767px){
                   & {
                       margin-left: 0;
                       margin-right: 0;
                   }
               }                        
               
               .event-thumb {
                    margin-bottom: 2em;                    
                }
               
                .event-thumb > img {
                   width: 100%;
                   border-top-left-radius: 6px;
                   border-top-right-radius: 6px;
                }
                
                .icon.date {
                    top: -7%;
                    left: -6%;
                }
           }
           
       }
   }
   
   .row{
      text-align:center;
      
      .tab-content__container {
          margin-bottom: 0;
          
          .tab-content.event {
                padding: 0;
                margin-bottom: 5em;
                
                &:before {
                    top:0; bottom: 0; left: 0; right: 0;
                    width: 100%;
                    height: 100%;
                }            
                
                @media only screen and (max-width:767px){
                    flex: 0 0 100%;
                    
                    .event-thumb img {
                        width: 100%;
                    }
                }
                
                h3 {
                    margin-bottom: 1em;
                }
                
                .icon.date {
                    
                }
          }
      }
      
   }
   .agenda-main-text{
      width:100%;
      max-width:767px;
      margin:0 auto 40px;
   }
   .agenda-button{
      .translate(0, -48px);
      background-color: @darkBlue;
      border-color: @darkBlue;
      padding-bottom: 6px;
      padding-top: 6px;
      margin-top: 4em;
   }
}



.frontpage {
    #agenda {
        background-color: @white;        
        .tab-content.event {
            padding: 0;
            max-width: 280px;
            margin: 0px 40px 40px;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.20);
            border-radius: 0;
            .event-thumb {
                overflow: hidden;
                display: inline-flex;
            }
            h3.event-title {
                padding: 1.5rem;                
            }
            
            .read-more {
                right: 20px;
                text-transform: uppercase;
                line-height: 24px;
                
                padding: 3px 15px;
            }
            .icon {
                top: -10%;
                left: -10%;
            }
            .event-thumb > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        
        @media only screen and (max-width:767px){
            &{
                .tab-content__container {
                    justify-content: center;
                    margin-bottom: 60px;
                    
                    .tab-content.event {
                        margin-bottom: 5rem;
                    }
                }
            }
        }
    }
    
    section.agenda-intro {
        background-color: @white;
        padding:36px 0 15px;
        
        position:relative;
        overflow:hidden;

        .row{
            text-align:center;
            max-width: 768px;
            margin-left: auto;
            margin-right: auto;
            
            h3 {
                line-height: 1.8;
            }
        }
    }
}
