.social-buttons{
   li{
      width:36px;
      height:36px;
      position:relative;
      float:left;
      margin:8px 6px;
      &:hover{
         a{
            .bg{
               opacity:1;
               transform:translate(-50%, -50%) scale(1.2);
            }
         }
      }
      a{
         position:absolute;
         top:0;
         left:0;
         width:100%;
         height:100%;

         .bg{
            width:100%;
            height:100%;
            top:50%;
            left:50%;
            transform:translate(-50%, -50%) scale(1);
            position:absolute;
            opacity:0;
            .transition(all 0.8s 0s @easeOutExpo);
            path{
               fill:@yellow;
            }
         }
         .icon{
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            svg{
               position:absolute;
               top:0;
               left:0;
               width:100%;
               height:100%;
               .scale(0.5);
               fill:@white;
            }
         }
      }
   }
}
