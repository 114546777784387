section#home-fourblocks {
    padding-bottom: 4rem;
    padding-top: 4rem;
        
    .four-blocks-wrap{
        display: flex;
        flex-wrap: wrap;       
        
        .item {
            a:hover {
                text-decoration: none;
            }
            
            flex: 0 0 ~"calc(49% - 4em)";          
            min-height: 240px;
            position: relative;
            margin-bottom: 4rem;
            
            .button {
                cursor: pointer;
            }
            
            &.even{
                margin-right: 2em;
            }
            &.odd{
                margin-left: 2em;
            }
            
            .more-link {
                position: absolute;
                bottom: -15px;
                right: 2rem;
            }
            
            .image-thumb {
                height: 240px;
                background-size: cover;
                background-repeat: no-repeat;
                                
                &::before{
                    content: '';
                    position: absolute;
                    right: 0;
                    left: 0;
                    bottom: 0;
                }
            }
            
            @media only screen and (max-width:1140px){
                flex: 0 0 ~"calc(49% - 4em)";
            }
            @media only screen and (max-width:767px){
                flex: 0 0 ~"calc(100%)";
                &.even{
                    margin-right: 0;
                }
                &.odd{
                    margin-left: 0;
                }
            }
            @media only screen and (max-width:560px){
               
            }
        }
    }
    
    h3.title {
        position: relative;
        padding-bottom: 12px;
        margin-bottom: 24px;
        &::after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0%;
            -webkit-transform: translate(0%, -50%);
            -moz-transform: translate(0%, -50%);
            -o-transform: translate(0%, -50%);
            -ms-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
            width: 80px;
            background: #da052d;
            height: 2px;
        }
    }
}