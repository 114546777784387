section#about{
   width:100%;
   padding:36px 0;
   margin-bottom:60px;
   h2{
      text-align:center;
   }
   .row{
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
   }
   .image__container{
      width:50%;
      float:left;
      @media only screen and (max-width:767px){
         width:100%;
         float:none;
      }
      img{
         width:~"calc(100% - 30px)";
         @media only screen and (max-width:767px){
            width:100%;
         }
      }
   }
   .text__container{
      width:50%;
      float:left;
      @media only screen and (max-width:767px){
         width:100%;
         float:none;
         margin-top:24px;
      }
   }

}
